.setting-page {
    .aui-sidenav-plane {
        flex-basis: 250px;
        flex-shrink: 0;
    }
    .hospital-banner {
        background: #c10e21;
        color: #fff;
        .hospital-banner-title,
        .hospital-banner-detail {
            background: #c10e21;
        }
        .hospital-banner-title {
            font-weight: 600;
        }
        .hospital-banner-detail {
            padding-top: 0;
        }
    }
    .prg-title {
        border-bottom: 2px solid #c10e21;
    }
    .setting-container {
        @media only screen and (min-width: 992px) {
            flex-grow: 1;
            padding: 10px 0 0 42px;
        }
    }
}

