.certificate-modal {
    .modal-content {
        padding: 0;
        border: none;
    }
    .modal-header {
        background-color: #c10e21;
        padding: 16px 22px;
        align-items: center;
        border-radius: 0;
        margin: 0;
        * {
            color: #fff;
            opacity: 1;
        }
        button:focus {
            outline: 2px solid #f7a14b !important;
            -webkit-box-shadow: 0 0 4px 4px #f7a14b !important;
            box-shadow: 0 0 4px 4px #f7a14b !important;
        }
    }
    .modal-body {
        padding: 20px 22px;
        text-align: center;
        margin: 0;
        min-height: 120px;
        width: 100%;
        @media only screen and (min-width: 576px) {
            min-height: 300px;
        }
        @media only screen and (min-width: 768px) {
            min-height: 500px;
        }
        canvas {
            max-width: 100%;
            @media only screen and (max-width: 991px) {
                height: auto !important;
            }
        }
        img {
            width: 100%;
        }
    }
    .modal-footer {
        background-color: #f8f8f8;
        padding: 20px 22px;
    }
}