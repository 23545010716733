.measure-indicator{
    font-size: 14px;
    padding: 1px 7px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-weight: 500;
}
.measure-submitted{
    border: 1px solid #329300;
    background: #EAF4E5;
    color: #329300;
}
.measure-saved{
    border: 1px solid #D69456;
    background: #FBF4EE;
    color: #D69456;
}
.measure-no-data{
    border: 1px solid #C1272D;
    background: #F9E9EA;
    color: #C1272D;
}
.measure-submitted{
    border: 1px solid #329300;
    background: #EAF4E5;
    color: #329300;
}
.warning-help {
    color: rgb(193, 14, 33);
    font-size: 15px;
    background-color: transparent;
    border: none;
    margin: 0 6px;
    padding: 0;
}
.reviewed-tooltip {
    .tooltip-inner {
        width: 120px;
        max-width: none;
        text-align: left;
        @media only screen and (min-width: 768px) {
            width: 200px;
        }
    }
     
    .tooltip-inner strong {
        display: block; 
        margin-bottom: 0;
    }
}