.admin-main-content {
    @media only screen and (min-width: 992px) {
        width: 73%;
        &.container {
            padding: 0;
            margin: 0;
            max-width: none;
        }
    }
    @media only screen and (min-width: 1200px) {
        width: 78%;
    }
    div.hospital-banner {
        border-color: #C10E21;
    }
    .user-mgnt {
        margin-top: 16px;
    }
}
.admin-main {
    @media only screen and (min-width: 992px) {
        padding: 30px 30px 44px 30px;
    }
    .docs-container {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }
    .admin-main-tabs {
        padding-top: 14px;
        > .tab-pane {
            padding: 10px 0;
        }
    }
}
.doc-action-btns {
    i {
        font-size: 20px;
    }
}
.hospital-status-table {
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 30%;
            }
            th:nth-child(2) {
                width: 25%;
            }
            th:nth-child(3) {
                width: 25%;
            }
            th:nth-child(4) {
                width: 20%;
            }
        }
    }
}
.pending-hospital-status-table {
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 38%;
            }
            th:nth-child(2) {
                width: 31%;
            }
            th:nth-child(3) {
                width: 31%;
            }
        }
    }
}
.logs-table {
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 15%;
            }
            th:nth-child(2) {
                width: 25%;
            }
            th:nth-child(3) {
                width: 30%;
            }
            th:nth-child(4) {
                width: 30%;
            }
        }
    }
}
.listing-table {
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 30%;
            }
            th:nth-child(2) {
                width: 20%;
            }
            th:nth-child(3) {
                width: 15%;
            }
            th:nth-child(4) {
                width: 20%;
            }
            th:nth-child(5) {
                width: 15%;
            }
        }
    }
}
.hospital-status-filters {
    select {
        width: 128px;
    }
}
.hospital-status-header {
    border: 1px solid #e3e3e3;
}
.registry-table {
    thead {
        @media only screen and (min-width: 992px) {
            th:nth-child(1) {
                width: 20%;
            }
            th:nth-child(2) {
                width: 40%;
            }
            th:nth-child(3) {
                width: 40%;
            }
        }
    }
    a {
        color: #C10E21;
    }
}
.registry-mgnt-header {
    border-bottom: 2px solid #c10e21;
}
.registry-title-border {
    border-bottom: 3px solid #c10e21;
    margin-bottom: 36px;
    .registry-edit {
        color: #c10e21;
        font-size: 24px;
        span {
            font-size: 16px;
        }
        &:hover {
            text-decoration: none;
        }
    }
}
.hospital-banner {
    border: 2px solid hsl(354, 86%, 41%);
    font-size: 18px;
    word-break: break-word;
    .hospital-banner-title {
        padding: 12px 14px;
        margin: 0;
        background: #F8F8F8;
        @media only screen and (min-width: 576px) {
            padding: 14px 26px 12px;
        }
    }
    .hospital-banner-detail {
        padding: 12px 26px 14px;
    }
}