.cst-tooltip {
    position: relative;
    .tooltip {
        display: none;
        position: absolute;
        transform: none !important;
        left: 0px;
        will-change: none !important;
        opacity: 1;
        z-index: 10501;
        background-color: #ffffff;
    }
    .tooltip-inner {
        max-width: unset;
    }
    .tooltip-bottom {
        top: calc(100% + 10px) !important;
        &:before,
        &:after {
            content: '';
            position: absolute;
            border-style: solid;
            display: block;
            width: 0;
            z-index: 0;
            left: 12px;
            border-width: 0 9px 9px;
        }
        &:before {
            border-color: #e3e3e3 transparent;
            top: -9px;
        }
        &:after {
            border-color: #fff transparent;
            top: -8px;
        }
    }
    .tooltip-item {
        &:focus,
        &:hover {
            & + .tooltip {
                display: inline-block;
            }
        }
    }
}