.orgdetails-mgnt-tab {
    &.aui-accordion-arrowtab {
        ul {
            border: none;
        }
        li {
            > button.nav-link.tooltip-item {
                padding: 0;
            }
            &:nth-child(1),
            &:nth-child(1) button {
                z-index: 10;
            }
            &:nth-child(2),
            &:nth-child(2) button {
                z-index: 9;
            }
            &:nth-child(3),
            &:nth-child(3) button {
                z-index: 8;
            }
            &:nth-child(4),
            &:nth-child(4) button {
                z-index: 7;
            }
            &:nth-child(5),
            &:nth-child(5) button {
                z-index: 6;
            }
        }
        li > button.nav-link {
            margin: 0 -14px 6px 0;
            background: transparent;
            border: none;
            padding: 0;
            overflow: hidden;
            &[aria-selected="true"] {
                > span {
                    &:after {
                        border-color: #c10e21;
                        background-color: #c10e21;
                    }
                    > span {
                        background-color: #c10e21;
                        border-color: #c10e21;
                        color: #ffffff;
                        display: block;
                        &:before {
                            border-color: #c10e21;
                        }
                    }
                }
            }
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
                * {
                    cursor: not-allowed;
                }
            }
            > span {
                font-size: 16px;
                padding: 0;
                line-height: 16px;
                cursor: pointer;
                min-width: 160px;
                border-radius: 0;
                text-align: center;
                position: relative;
                margin: 0;
                width: 100%;
                overflow: hidden;
                display: block;
                > span {
                    background-color: #f8f8f8;
                    border-radius: 0;
                    margin: 0 22px 0 0;
                    position: relative;
                    display: block;
                    line-height: 16px;
                    border-right: none;
                    border-left: none;
                    border-top: 1px solid #e3e3e3;
                    border-bottom: 1px solid #e3e3e3;
                    width: auto;
                    padding: 10px 8px 10px 28px;
                    &:before {
                        content: "";
                        width: 28px;
                        height: 28px;
                        border-right: 1px solid #e3e3e3;
                        border-top: 1px solid #e3e3e3;
                        background-color: #ffffff;
                        position: absolute;
                        top: 4px;
                        left: -14px;
                        transform: rotate(49deg) skewy(-6deg);
                        z-index: 1;
                        display: block;
                    }
                }
                &:after {
                    content: "";
                    width: 28px;
                    height: 28px;
                    border-right: 1px solid #e3e3e3;
                    border-top: 1px solid #e3e3e3;
                    background-color: #f8f8f8;
                    position: absolute;
                    top: 5px;
                    right: 8px;
                    transform: rotate(48deg) skewy(-6deg);
                    z-index: -1;
                    display: block;
                }
            }
        }
        li.nav-item + li.nav-item {
            margin-left: 0;
        }
        li.nav-item {
            button.nav-link {
                padding: 0;
            }
        }
    }
}