.expire-tooltip {
    border: none;
    background: none;
    width: fit-content;
}
.expire-info {
    .tooltip-inner{
        @media only screen and (min-width: 576px) {
            width:240px;
            max-width: none;
        }
    }
}
.expire-yellow {
    color: #815E0B;
    .expire-tooltip {
        color: #815E0B;
    }
}
.expire-red {
    color: #c10e21;
    .expire-tooltip {
        color: #c10e21;
    }
}