.cst-search-select {
    width: 100%;
    word-break: break-all;
    word-break: break-word;
    position: relative;
}
.cst-search-select-fld {
    height: 40px;
    border: 1px solid #777979;
    border-radius: 2px;
    width: 100%;
    cursor: pointer;
    background: #ffffff;
    position: relative;
    &:focus {
        outline: 2px solid #f7983a;
        box-shadow: 0 0 4px 4px #f7983a;
    }
    &.cst-search-multiselect-fld {
        min-height: 42px;
        height: auto;
        padding-right: 40px;
        .cst-search-selected-item {
            position: absolute;
            left: -10px;
            top: -10px;
            width: 1px;
            height: 1px;
        }
        .aui-tag-list {
            margin-top: 2px;
        }
    }
}
.search-selected-value {
    position: absolute;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    z-index: 1;
}
// .cst-search-selected-item[value=""] + .search-selected-value {
//   display: block;
// }
// .cst-search-selected-item:not([value=""]) + .search-selected-value {
//   display: none;
// }
.cst-search-select-required {
    display: none;
}
.required {
    .cst-search-select-required {
        display: inline-block;
    }
}
.cst-search-selected-item {
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: hidden;
    word-break: break-all;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    padding: 6px 26px 6px 12px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
    border: none;
}
body:not(.Firefox) {
    .cst-search-selected-item-firefox {
        display: none;
    }
}
.Firefox {
    .cst-search-selected-item {
        font-size: 0.1px;
        &:focus {
            font-size: 16px;
            + .cst-search-selected-item-firefox {
                display: none;
            }
        }
    }
    .cst-search-selected-item-firefox {
        width: 100%;
        height: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 6px 26px 6px 12px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
.loader-space {
    padding: 6px 24px 6px 12px;
}
.cst-search-arrow {
    width: 25px;
    height: 35px;
    display: inline-block;
    background: transparent;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    font-size: 8px;
    padding-right: 6px;
    z-index: 1;
    border: none;
    &:before {
        line-height: 36px;
        margin: 0 auto;
        display: block;
    }
    &.cst-search-arrow-up {
        transform: rotate(180deg);
        transition: all 0.3s ease;
    }
    &.cst-search-arrow-down {
        transform: rotate(0deg);
        transition: all 0.3s ease;
    }
}
.cst-search-select-dropdown {
    width: 100%;
    border: 1px solid #bcc3ca;
    position: absolute;
    margin-top: 5px;
    left: 0;
    background-color: #fff;
    z-index: 99999;
    list-style-type: none;
    padding: 4px 0 1px;
    box-shadow: 0 0 1.125rem #00000029;
    border-radius: 2px;
    > ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        max-height: 292px;
        overflow: auto;
    }
    .cst-search-item {
        border-bottom: 1px solid #e3e3e3;
        cursor: pointer;
        padding: 6px 8px;
        margin: 0 2px;
        &:focus {
            outline: 2px solid #f7983a;
            box-shadow: 0 0 4px 4px #f7983a;
        }
        &.cst-search-item-disabled {
            pointer-events: none;
            color: #979797;
            background-color: #f8f8f8;
        }
    }
    .cst-search-item li:last-child {
        border-bottom: 0;
    }
    .cst-search-item-selected {
        color: #c10e21;
    }
    .form-check-bordered {
        z-index: -1;
    }
}
.cst-search-select-disabled {
    cursor: not-allowed;
    .cst-search-select-fld {
        border: 1px solid #777979;
        pointer-events: none;
        background-color: #f8f8f8;
    }
}
.cst-search-select-loading {
    position: relative;
    cursor: not-allowed;
    &:after {
        border: 3px solid #222328;
        border-radius: 50%;
        border-top: 3px solid transparent;
        width: 22px;
        height: 22px;
        -webkit-animation: aui-btn-spin 2s linear infinite;
        animation: aui-btn-spin 2s linear infinite;
        content: "";
        display: inline-block;
        position: absolute;
        margin-left: 10px;
        top: 8px;
        right: 9px;
        z-index: 4;
    }
    .cst-search-selected-item {
        padding-right: 60px;
        background-color: #f8f8f8;
    }
    .cst-search-select-fld {
        pointer-events: none;
    }
    .cst-search-multiselect-fld {
        padding-right: 60px;
        background-color: #f8f8f8;
    }
}
.disable-title {
    .cst-search-select-dropdown {
        li:first-child {
            pointer-events: none;
        }
    }
}
.cst-search-clear {
    position: absolute;
    right: 30px;
    top: 11px;
    font-size: 14px;
    color: #222328;
    padding: 2px;
    z-index: 9;
    display: none;
}
.cst-search-selected-item:not(:placeholder-shown) + .cst-search-clear {
    display: block;
}
.cst-search-select-emptystate {
    padding: 6px 8px;
}
.cst-search-item-btn {
    text-align: left;
    padding: 6px 8px;
    margin: 0 2px;
    color: #c10e21;
    background-color: transparent;
    border: none;
    width: 100%;
    font-weight: 600;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
