.popover {
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 9px #00000029;
    border-radius: 0;
    font-size: 16px;
    max-width: 80vw;
    .arrow {
        display: none;
    }
}
.popover-body {
 padding: 16px;   
}

.measure-popover {
    &.measure-pop-xs {
        max-width: 200px;
        width: 200px;
    }
    &.measure-pop-sm {
        max-width: 230px;
        width: 230px;
        @media only screen and (min-width: 576px) {
            max-width: 400px;
            width: 400px;
        }
    }
    &.measure-pop-lg {
        max-width: 230px;
        width: 230px;
        @media only screen and (min-width: 576px) {
            max-width: 450px;
            width: 450px;
        }
        @media only screen and (min-width: 768px) {
            max-width: 600px;
            width: 600px;
        }
        @media only screen and (min-width: 992px) {
            max-width: 700px;
        }
    }
    margin: -16px;
    padding: 16px;
    max-height: 450px;
    overflow: auto;
    p:last-child {
        margin-bottom: 0;
    }
    .measure-pop-title {
        border-bottom: 1px solid #C10E21;
        margin-bottom: 12px;
        padding-bottom: 8px;
        font-size: 18px;
    }
    .measure-pop-subtitle {
        font-size: 16px;
        margin-bottom: 2px;
        font-weight: 500;
    }
    .measure-pop-list {
        list-style-type: disc;
        margin: 0 0 10px;
        padding: 0 0 0 20px;
        line-height: 1.6;
    }
    .measure-pop-nodisc {
        list-style-type: none;
        font-weight: 500;
        margin: 0;
    }
    .measure-p-nodisc {
        list-style-type: none;
    }

}
.emsprolabel {
    .tooltip-inner {
        .emsprogram-popover {
            text-align: left;
        }

    }
}

.dynamic-tooltip{
    .tooltip-inner {
        max-width: 90vw;
        .emsprogram-popover {
            max-width: 90vw;
            width: 230px;     
            margin: -16px;
            padding: 16px;
            max-height: 450px;
            overflow: auto;
            text-align: left;
            @media only screen and (min-width: 576px) {
                max-width: 400px;
                width: auto;
            }
            p:last-child {
                margin-bottom: 0;
            }
            .emsprogram-pop-list {
                list-style-type: disc;
                margin: 0 0 10px;
                padding: 0 0 0 20px;
                line-height: 1.6;
            }
        }
        .emsprogram-md-popover {
            max-width: 90vw;
            width: 230px;
            text-align: left;
            @media only screen and (min-width: 576px) {
                max-width: 450px;
                width: 450px;
            }
        }
        .vender-popup {
            max-width: 250px;
                width: 250px;
        }
    }
    
}
