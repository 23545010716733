.cst-select {
  width: 100%;
  word-break: break-all;
  word-break: break-word;
  position: relative;
}
.cst-select-fld {
  height: 40px;
  border: 1px solid #777979;
  border-radius: 2px;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  position: relative;
  &:focus {
    outline: 2px solid #f7983a;
    box-shadow: 0 0 4px 4px #f7983a;
  }
  &.cst-multiselect-fld {
    min-height: 42px;
    height: auto;
    padding-right: 40px;
    .cst-selected-item {
      position: absolute;
      left: -10px;
      top: -10px;
      width: 1px;
      height: 1px;
    }
    .aui-tag-list {
        margin-top: 2px;
    }
  }
}
.cst-select-required {
  display: none;    
}
.required {
  .cst-select-not-required {
    display: none;
  }
  .cst-select-required {
    display: inline-block;
  }
  .cst-select .cst-multiselect-fld .cst-grouped-select-required {
    display: inline-block;
  }
}
.cst-selected-item {
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
  word-break: break-all;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  padding: 6px 27px 6px 12px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
  color: #222328;
}
.cst-arrow {
  width: 25px;
  height: 35px;
  display: inline-block;
  background: transparent;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  font-size: 8px;
  padding-right: 6px;
  z-index: 2;
  &:before {
    line-height: 36px;
    margin: 0 auto;
    display: block;
  }
  &.cst-arrow-up {
    transform: rotate(180deg);
    transition: all 0.3s ease;
  }
  &.cst-arrow-down {
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
}
.cst-select-dropdown {
  width: 100%;
  max-height: 292px;
  border: 1px solid #bcc3ca;
  overflow: auto;
  position: absolute;
  margin-top: 5px;
  left: 0;
  background-color: #fff;
  z-index: 99999;
  list-style-type: none;
  padding: 4px 0 1px;
  box-shadow: 0 0 1.125rem #00000029;
  border-radius: 2px;
  .cst-item {
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer;
    padding: 6px 8px;
    margin: 0 2px;
    &:focus {
      outline: 2px solid #f7983a;
      box-shadow: 0 0 4px 4px #f7983a;
    }
    &.cst-item-disabled {
      pointer-events: none;
      color: #979797;
      background-color: #f8f8f8;
    }
  }
  .cst-item li:last-child {
    border-bottom: 0;
  }
  .cst-item-selected {
    color: #c10e21;
  }
  .form-check-bordered {
      z-index: -1;
  }
}
.cst-select-disabled {
  cursor: not-allowed;
  .cst-select-fld {
    border: 1px solid #777979;
    pointer-events: none;
    background-color: #f8f8f8;
  }
}
.cst-select-loading {
  position: relative;
  cursor: not-allowed;
  &:after {
    border: 3px solid #222328;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 22px;
    height: 22px;
    -webkit-animation: aui-btn-spin 2s linear infinite;
    animation: aui-btn-spin 2s linear infinite;
    content: '';
    display: inline-block;
    position: absolute;
    margin-left: 10px;
    top: 8px;
    right: 9px;
    z-index: 4;
  }
  .cst-selected-item {
    padding-right: 60px;
    background-color: #f8f8f8;
  }
  .cst-select-fld {
    pointer-events: none;
  }
  .cst-multiselect-fld {
    padding-right: 36px;
    background-color: #f8f8f8;
  }
  .cst-arrow {
    display: none;
  }
}
.disable-title {
  .cst-select-dropdown {
    li:first-child {
      pointer-events: none;
    }
  }
}