@import "./variables.scss";

* {
    line-height: 1.5;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.select-text,
.select-text * {
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}
input,
textarea,
select {
    -webkit-touch-callout: auto; /* iOS Safari */
    -webkit-user-select: auto; /* Safari */
    -khtml-user-select: auto; /* Konqueror HTML */
    -moz-user-select: auto; /* Old versions of Firefox */
    -ms-user-select: auto; /* Internet Explorer/Edge */
    user-select: auto; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.visuallyhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0;
    overflow: hidden;
}
.icon-bold {
    &:before {
        font-weight: bold;
    }
}
.modal {
    z-index: 10601;
}
.modal-backdrop {
    z-index: 10600;
}
.modal-backdrop + .modal-backdrop {
    opacity: 0;
}
.hidden {
    display: none !important;
}
.invisible-btn {
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.aui-modal.unmap-modal {
    .modal-content {
        padding: 0;
    }

    .modal-header {
        background-color: #f8f8f8;
        border: none;
        padding: 12px 18px 12px 22px;
        margin-bottom: 20px;

        .close {
            font-size: 12px;
        }
    }

    .modal-body {
        padding: 0 22px 18px;
        font-size: 16px;
        min-height: auto;
    }

    .doc-action-content {
        line-height: 28px;
    }

    textarea.form-control {
        height: 90px;
    }
}
.corporation-container {
    .container {
        .container {
            padding: 0;
        }
    }
}
.abbr-code {
    letter-spacing: -1.8px;
    white-space: pre-wrap;
}
.abbr-code-bold {
    letter-spacing: -2.5px;
    white-space: pre-wrap;
}
.tooltip {
    z-index: 10501;
}
.breadcrumb-item {
    display: inline-block;
    a {
        margin-right: 8px;
    }
    &:after {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6d6e71;
        content: "/";
    }
    &.active {
        a {
            color: #4d4c4c;
            text-decoration: none;
            &:focus,
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
    &:before {
        display: none;
    }
}
.font-14 {
    font-size: 14px;
}
.aui-table-loader + .aui-table-loader {
    display: none;
}
.btn.btn-primary {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
.btn-sm {
    &.aui-btn-spinner {
        padding: 0.38rem 1.99rem 0.38rem 0.99rem !important;
        &:after {
            border: 2px solid #fff;
            border-top: 2px solid transparent;
            width: 12px;
            height: 12px;
            margin-left: 0.25rem;
            top: 0.53rem;
        }
    }
}
.form-group label {
    margin-bottom: 0;
    display: inline-block;
}
.aui-global-menu {
    li {
        a:focus,
        a:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
button:disabled {
    outline: none !important;
    box-shadow: none !important;
}
.form-help {
    font-size: 12px;
    line-height: 1.7;
    text-align: right;
    color: #676060;
}
.tooltip-div {
    padding: 12px;
    background-color: #ffffff;
    position: absolute;
    top: 50px;
    right: 20%;
    display: none;
    z-index: 9;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 9px rgb(0 0 0 / 16%);
    transform: translate(10%, -10px);
    font-size: 14px;
    white-space: break-spaces;
    font-weight: 400;
    @media only screen and (min-width: 768px) {
        right: 30%;
        transform: translate(30%, 0);
    }
    @media only screen and (min-width: 992px) {
        right: 50%;
        transform: translate(50%, 0);
    }
    &:after {
        content: "";
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: #ffffff transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -10px;
        left: 48%;
    }
    &:before {
        content: "";
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: #e3e3e3 transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -10px;
        left: 48%;
    }
}
.aui-responsive-status-table.aui-table-loader {
    .tooltip-div {
        display: none !important;
    }
}
.modal-backdrop {
    display: none;
}
.modal-open {
    .modal-backdrop {
        display: block;
    }
}
.aui-dark-nav {
    .aui-dark-nav-menu {
        a,
        button {
            &:focus {
                outline: 2px solid #f7a14b !important;
                -webkit-box-shadow: 0 0 4px 4px #f7a14b !important;
                box-shadow: 0 0 4px 4px #f7a14b !important;
            }
        }
    }
}
.label-helptext {
    font-size: 12px;
}
div[tabindex="0"] {
    &:focus {
        outline: 2px solid #d6700a !important;
        -webkit-box-shadow: 0 0 4px 4px #d6700a !important;
        box-shadow: 0 0 4px 4px #d6700a !important;
    }
    &:hover {
        cursor: pointer;
    }
}
.google-visualization-tooltip {
    background-color: #ffffff !important;
    border: 1px solid #d0d0d0 !important;
    margin-left: -16px !important;
    margin-bottom: -30px !important;
    margin-top: 40px !important;
    z-index: +1;
    border-radius: 6px !important;
    box-shadow: none !important;
    pointer-events: none !important;
    text-align: left !important;
    width: 322px !important;
}
.aui-footer .aui-footer-linkitem li a:not([href]):hover {
    text-decoration: underline;
    color: #c10e21;
    cursor: pointer;
}
.aui-global-nav,
.aui-sidenav {
    .aui-global-menubtn {
        @media only screen and (max-width: 991.98px) {
            padding: 0;
        }
    }
    .navbar-toggler.btn-mainmenu-sm {
        border: none;
        background: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px 18px;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        width: 100%;
        color: #222328;
        i {
            font-size: 8px;
        }
        @media only screen and (min-width: 576px) {
            padding: 16px 20px;
        }
        @media only screen and (min-width: 768px) {
            padding: 16px 40px;
        }
        @media only screen and (min-width: 992px) {
            display: none;
        }
    }
}
.btn-mainmenu-sm {
    border: none;
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 18px;
    color: #222328;
    @media only screen and (min-width: 576px) {
        padding: 16px 20px;
    }
    @media only screen and (min-width: 768px) {
        padding: 16px 40px;
    }
}
.btn-mainmenu-sm i {
    font-size: 10px;
}
.table-emptystate-outer div {
    border: 1px solid #e3e3e3;
    text-align: center;
    font-size: 14px;
    padding: 12px;
}
.aui-responsive-status-table.aui-table-loader + .table-emptystate-outer {
    display: none;
}
.aui-responsive-status-table + .table-emptystate-outer div {
    @media only screen and (min-width: 992px) {
        border-top: none;
    }
}
.mand-field {
    position: relative;
    padding-left: 12px;
    sup {
        font-size: 20px;
        position: absolute;
        top: 14px;
        left: 0;
        color: #c10e21;
    }
}
.form-group.required {
    .label-form:after {
        display: none;
    }
    sup {
        color: #c10e21;
        font-size: 18px;
        vertical-align: sub;
    }
}
.popupHide {
    display: none;
}
.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.47);
    top: 0;
    bottom: 0;
}
.lock-alert {
    font-size: 0.8rem;
    border: solid 0.1px #d5a940;
    padding: 9px 15px !important;

    .aha-icon-warning {
        font-size: 0.78rem;
    }
}
.aui-modal {
    div[tabindex="-1"]:focus {
        outline: 1px solid #f7a14b !important;
        -webkit-box-shadow: 0 0 3px 3px #f7a14b !important;
        box-shadow: 0 0 3px 3px #f7a14b !important;
    }
    .modal-sm {
        .modal-content {
            padding: 20px;
            .modal-header {
                border-color: #c10e21;
                padding-bottom: 12px;
                margin-bottom: 14px;
            }
        }
    }
}


input::placeholder {
    font-size: 14px;
}
input:placeholder-shown {
    text-overflow: ellipsis;
}
input::placeholder {
    font-size: 14px;
}
